import { makeStyles, Button } from "@fluentui/react-components";
import { ChatBubblesQuestion24Regular } from "@fluentui/react-icons";
import { ChatAppResponse } from "../../../../api";
import { UserChatHistoryTable } from "../UserChatHistoryTable/UserChatHistoryTable";

const useClasses = makeStyles({
    header: {
        display: "flex",
        padding: "8px 6px 8px 10px",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "4px",

        color: "#242424",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal"
    },

    control: {
        margin: "30px 0",
        textAlign: "center"
    },

    panelItem: {
        padding: "4px 6px 8px 10px",
        justifyContent: "space-between"
    },

    button: {
        margin: "0 auto !important",
        padding: "10px 20px !important",
        backgroundColor: "#fff !important",
        color: "#193c96 !important",
        border: "2px solid #ccc !important",
        borderRadius: "5px !important",
        fontSize: "14px",
        fontWeight: "bold !important"
    }
});

export interface UserChatHistoryProps {
    chatHistory: [user: string, response: ChatAppResponse][][];
    onQuestionClick: (index: number) => void;
    onStartNewChat: () => void;
    onClose: () => void;
}

export interface StartNewButtonProps {
    onStartNewChat: () => void;
    onClose: () => void;
}

export const UserChatHistory = ({ chatHistory, onQuestionClick, onStartNewChat, onClose }: UserChatHistoryProps) => {
    const classes = useClasses();
    return (
        <div className={classes.panelItem}>
            <UserChatHistoryHeader />
            <UserChatHistoryTable chatHistory={chatHistory} onQuestionClick={onQuestionClick} onClose={onClose} />
            <StartNewChatButton onStartNewChat={onStartNewChat} onClose={onClose} />
        </div>
    );
};

const UserChatHistoryHeader = () => {
    const classes = useClasses();
    return <div className={classes.header}>Chat History</div>;
};

const StartNewChatButton = ({ onStartNewChat, onClose }: StartNewButtonProps) => {
    const classes = useClasses();

    const onClick = () => {
        onStartNewChat();
    };

    return (
        <div className={classes.control}>
            <Button className={classes.button} onClick={onClick} icon={<ChatBubblesQuestion24Regular />}>
                &nbsp;&nbsp;Start&nbsp;New&nbsp;Chat
            </Button>
        </div>
    );
};
