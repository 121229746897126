import { ChangeEvent, ReactNode } from "react";

import { useMsal } from "@azure/msal-react";

import { HiddenFileInput } from "../../HiddenFileInput";
import { getUploadableExtensions } from "../FileExtensions";
import { getToken } from "../../../authConfig";
import { queueFilesForUpload } from "../../../state/FileHistoryStore";
import { filterUniqueFiles } from "./utilities";

export type HiddenUploadFileInputProps = {
    className?: string;
    children: ReactNode;
};

const accept = Array.from(getUploadableExtensions()).join(", ");

export const HiddenUploadFileInput = (props: HiddenUploadFileInputProps) => {
    const client = useMsal().instance;

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const fileList = event.target.files;

        if (!fileList || fileList.length === 0) {
            return;
        }

        const files: File[] = [];
        for (const file of fileList) {
            files.push(file);
        }

        const uniqueFiles = filterUniqueFiles(files);

        const idToken = await getToken(client);
        if (idToken) {
            await queueFilesForUpload(uniqueFiles, idToken);
        }

        event.target.value = "";
    };

    return (
        <HiddenFileInput className={props.className} accept={accept} onChange={onChange} multiple>
            {props.children}
        </HiddenFileInput>
    );
};
