import { useEffect, useState } from "react";
import { makeStyles, Button, Dialog } from "@fluentui/react-components";
import { IconX } from "@tabler/icons-react";
import UserProfileForm from "./UserProfileForm";

const useClasses = makeStyles({
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(16, 54, 153, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        "@media (max-width: 600px)": {
            justifyContent: "center",
            alignItems: "center"
        }
    },
    panel: {
        width: "380px",
        padding: "20px",
        overflow: "visible",
        maxHeight: "none",
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 600px)": {
            maxHeight: "none",
            maxWidth: "80vw",
            overflowY: "auto"
        }
    },
    container: {
        padding: "6px",
        flexDirection: "column",
        alignItems: "flex-start",
        background: "#ffffff",
        overflow: "visible",
        boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
        "@media (max-width: 600px)": {
            padding: "10px",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)"
        }
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#103699",
        fontFamily: "Poppins",
        fontWeight: "bold",
        fontSize: "1.2em"
    },
    headerText: {
        flex: 1,
        textAlign: "center"
    },
    closeButton: {
        minWidth: "16px"
    }
});

export interface UserProfilePanelProps {
    isOpen: boolean;
    onClose: () => void;
    onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isProfileUpdate: boolean;
    isSaving: boolean;
    errorMessage: string;
}

export const UserProfilePanel = (props: UserProfilePanelProps) => {
    const classes = useClasses();
    return (
        <div className={classes.overlay}>
            <Dialog open={props.isOpen} onOpenChange={(_event, data) => !data.open && props.onClose()}>
                <div className={classes.panel}>
                    <div className={classes.container}>
                        <div className={classes.header}>
                            <div className={classes.headerText}>{props.isProfileUpdate ? "Update your profile" : "Create your profile"}</div>
                            {props.isProfileUpdate && (
                                <Button className={classes.closeButton} aria-label="Close dialog" onClick={props.onClose}>
                                    <IconX color="#797979" size={16} />
                                </Button>
                            )}
                        </div>
                        <UserProfileForm
                            onFormSubmit={props.onFormSubmit}
                            isProfileUpdate={props.isProfileUpdate}
                            isSaving={props.isSaving}
                            errorMessage={props.errorMessage}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
