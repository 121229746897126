import { useState, DragEvent, ReactNode } from "react";
import { mergeClasses } from "@fluentui/react-components";

import { useMsal } from "@azure/msal-react";

import { getToken } from "../../../authConfig";
import { queueFilesForUpload } from "../../../state/FileHistoryStore";
import { appendFileSystemEntryToFileArray, filterUniqueFiles } from "./utilities";

export type FileDropzoneProps = {
    classContainer?: string;
    classContainerOver?: string;
    children: ReactNode;
};

export const FileDropzone = (props: FileDropzoneProps) => {
    const [isOver, setIsOver] = useState(false);

    const client = useMsal().instance;

    const containerClassName = isOver ? mergeClasses(props.classContainer, props.classContainerOver) : props.classContainer;

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const onDragEnter = () => setIsOver(true);

    const onDragLeave = () => setIsOver(false);

    const onDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        setIsOver(false);

        const files: File[] = [];
        const items = event.dataTransfer.items;
        if (items) {
            const promises: Promise<null>[] = [];
            for (const item of items) {
                const entry = item.webkitGetAsEntry();
                if (entry) {
                    promises.push(appendFileSystemEntryToFileArray(entry, files));
                }
            }
            Promise.allSettled(promises).finally(async () => {
                const uniqueFiles = filterUniqueFiles(files);

                const idToken = await getToken(client);
                if (idToken) {
                    await queueFilesForUpload(uniqueFiles, idToken);
                }
            });
        }
    };

    return (
        <div className={containerClassName} onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            {props.children}
        </div>
    );
};
