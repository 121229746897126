import styles from "./Splash.module.css";

import { useLogin } from "../../authConfig";
import { LoginButton } from "../../components/LoginButton";

const Splash = () => {
    return (
        <div className={styles.splashHeroContainer}>
            <p className={styles.splashHeroHeader}>{"AI-driven SDLC automation"}</p>
            <p className={styles.splashHeroBody}>
                {
                    "Speed development and increase team productivity with Intelligenic’s automated product lifecycle platform. AI-based prescriptive guidance and end-to-end orchestration turn product managers into product heroes."
                }
            </p>
            <div className={styles.splashHeroSignInContainer}>{useLogin && <LoginButton />}</div>
        </div>
    );
};

export default Splash;
