const UPLOADABLE_EXTENSIONS = [
    "txt",
    "md",
    "json",
    "png",
    "jpg",
    "jpeg",
    "bmp",
    "heic",
    "tiff",
    "pdf",
    "docx",
    "xlsx",
    "pptx",
    "html",
    "mp3",
    "wav",
    "mp4",
    "mov",
    "avi",
    "mkv"
];

const EXTENSION_TO_TYPE = {
    txt: "text",
    md: "text",
    json: "json",
    png: "image",
    jpg: "image",
    jpeg: "image",
    bmp: "image",
    heic: "image",
    tiff: "image",
    pdf: "document",
    docx: "document",
    xlsx: "document",
    pptx: "document",
    html: "text",
    mp3: "audio",
    wav: "audio",
    mp4: "video",
    mov: "video",
    avi: "video",
    mkv: "video",
    transcript: "transcript"
};

export function getUploadableExtensions(): Set<string> {
    return new Set(UPLOADABLE_EXTENSIONS);
}

export function getExtensionToType(): Map<string, string> {
    return new Map(Object.entries(EXTENSION_TO_TYPE));
}

export function getExtension(filename: string): string {
    const parts = filename.split(".");
    const extension = parts.pop();

    if (parts.length > 0 && parts[0] !== "" && extension) {
        return extension.toLowerCase();
    } else {
        return "";
    }
}

export function getType(filename: string): string | undefined {
    const extension = getExtension(filename);
    const extensionToType = getExtensionToType();

    return extensionToType.get(extension);
}

export function isAVFile(filename: string): boolean {
    const extension = getExtension(filename);
    const extensionToType = getExtensionToType();
    const type = extensionToType.get(extension);

    return type === "audio" || type === "video";
}
