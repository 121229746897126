import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { fetchUserProfile, UserProfiles } from "../api";
import { useMsal } from "@azure/msal-react";
import { getToken } from "../authConfig";
import { appServicesToken } from "../authConfig";

interface UserContextType {
    userProfile: UserProfiles | null;
    setUserProfile: React.Dispatch<React.SetStateAction<UserProfiles | null>>;
    updateUserProfile: (newProfile: UserProfiles) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userName: string = activeAccount?.username ?? (appServicesToken?.user_claims?.preferred_username || "Guest");
    const client = useMsal().instance;

    const [userProfile, setUserProfile] = useState<UserProfiles | null>(null);

    useEffect(() => {
        const initializeUserProfile = async () => {
            if (!userProfile) {
                await client.initialize();
                withAuthentication((idToken: string) => {
                    fetchUserProfile(userName, idToken)
                        .then(profileData => {
                            setUserProfile(profileData);
                        })
                        .catch(error => {
                            console.error(`Error fetching user profile: ${userName}`, error);
                        });
                });
            }
        };

        initializeUserProfile();
    }, [userName, userProfile]);

    const withAuthentication = (apiCall: (idToken: string) => void) => {
        try {
            if (!client) {
                throw new Error("Auth client is undefined.");
            }

            getToken(client).then(idToken => {
                if (!idToken) {
                    throw new Error("No authentication token available");
                }
                apiCall(idToken);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const updateUserProfile = (newProfile: UserProfiles) => {
        setUserProfile(newProfile);
    };

    return <UserContext.Provider value={{ userProfile, setUserProfile, updateUserProfile }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
