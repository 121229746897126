import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig, useLogin } from "./authConfig";
import { UserProvider } from "./context/userContext";
import { Provider } from "react-redux";
import store from "./state/store";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { FluentProvider, makeStyles, webLightTheme, PartialTheme, Theme } from "@fluentui/react-components";

export const useStyles = makeStyles({
    root: {
        height: "100%"
    }
});

const partialTheme: PartialTheme = {};

import Integrations from "./pages/integrations/Integrations";

var layout;
if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <UserProvider>
                <Layout />
            </UserProvider>
        </MsalProvider>
    );
} else {
    layout = (
        <UserProvider>
            <Layout />
        </UserProvider>
    );
}

initializeIcons();

const LazyIntegrations = React.lazy(() => import("./pages/integrations/Integrations"));

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "qa",
                lazy: () => import("./pages/ask/Ask")
            },
            {
                path: "integrations",
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <LazyIntegrations />
                    </Suspense>
                )
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

function App() {
    const styles = useStyles();
    return (
        <React.StrictMode>
            <FluentProvider className={styles.root} theme={partialTheme}>
                <RouterProvider router={router} />
            </FluentProvider>
        </React.StrictMode>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <Provider store={store}>
        <App />
    </Provider>
);
