import React, { useEffect, useRef, useState } from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { useMsal } from "@azure/msal-react";

import { appServicesToken } from "../../authConfig";
import { LoginButton } from "../../components/LoginButton";
import { UserProfileButton } from "./UserProfileButton/UserProfileButton";
import { UserProfile } from "../../components/Profile/UserProfile";

const useStyles = makeStyles({
    avatar: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        color: "#ffffff",
        backgroundColor: "#103699",

        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",

        ...shorthands.margin("0"),
        height: "28px",
        width: "28px",
        ...shorthands.borderRadius("50%"),

        cursor: "pointer"
    },
    menu: {
        position: "absolute",
        left: "50%",
        bottom: "50%"
    },
    hidden: {
        display: "none"
    }
});

export const Profile = () => {
    const classes = useStyles();
    const avatarRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const [menuIsHidden, setMenuIsHidden] = useState(true);
    const [isUserProfileOpen, setIsUserProfileOpen] = useState<boolean>(false);

    const { instance } = useMsal();

    let menuClasses = classes.menu;
    if (menuIsHidden) {
        menuClasses += " " + classes.hidden;
    }

    useEffect(() => {
        const handler = (event: MouseEvent) => {
            if (event.target instanceof HTMLElement && !menuRef.current?.contains(event.target) && !avatarRef.current?.contains(event.target)) {
                setMenuIsHidden(true);
            }
        };

        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        };
    }, []);

    const toggleMenu = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (!event || (event.target instanceof HTMLElement && !menuRef.current?.contains(event.target))) {
            setMenuIsHidden(menuIsHidden => !menuIsHidden);
        }
    };

    const activeAccount = instance.getActiveAccount();
    let username = activeAccount?.username ?? appServicesToken?.user_claims?.preferred_username;
    let name;
    if (username) {
        name = username[0].toUpperCase();
    }

    const closeUserProfile = () => {
        setIsUserProfileOpen(false);
    };

    const toggleUserProfile = () => {
        setIsUserProfileOpen(prevState => {
            // Prevent re-showing the menu if modal is open
            if (prevState === true) {
                return true;
            }
            return !prevState;
        });
        toggleMenu();
    };

    return (
        <div>
            <div className={classes.avatar} ref={avatarRef} onClick={toggleMenu}>
                {name}
                <div className={menuClasses} ref={menuRef}>
                    <LoginButton />
                    <UserProfileButton onProfileButtonClick={toggleUserProfile} />
                </div>
            </div>
            <UserProfile isOpen={isUserProfileOpen} onClose={() => setIsUserProfileOpen(false)} userName={username || "Guest"} isProfileUpdate={true} />
        </div>
    );
};
