import { create } from "zustand";
import { enableMapSet, WritableDraft } from "immer";
import { immer } from "zustand/middleware/immer";
import { FFmpeg } from "@ffmpeg/ffmpeg";

import { deleteUserFileApi, listUserFilesApi, uploadUserRegularFileApi, uploadUserAVFileApi, UserFile } from "../api";
import { getType, isAVFile } from "../components/FileHistory";
import { load as ffmpegLoad, transcode as ffmpegTranscode } from "../components/FileHistory/components/ffmpeg";

const CONCURRENT_REGULAR_FILE_UPLOADS = import.meta.env.VITE_CONCURRENT_REGULAR_FILE_UPLOADS || 3;
// const CONCURRENT_AV_FILE_UPLOADS = import.meta.env.VITE_CONCURRENT_AV_FILE_UPLOADS || 1;
const CONCURRENT_AV_FILE_UPLOADS = 1; // Currently only supports one single AV uploader

enableMapSet();

export type UploadFile = UserFile & { file: File; status: "pending" | "processing" | "uploading" | "error" };

export type FileHistoryStore = {
    isInitialized: boolean;
    hasReceivedServerFiles: boolean;
    hasLoadedFFmpeg: boolean;
    serverFiles: Map<string, UserFile>;
    deletingFiles: Set<string>;
    uploadingFiles: Map<string, UploadFile>;
    toUploadRegularFiles: string[];
    toUploadAVFiles: string[];
    toUploadFilesWaitingDeletion: Set<string>;
    inFlightRegularFiles: Set<string>;
    inFlightAVFiles: Set<string>;
    inFlightErrorFiles: Set<string>; // do i need?
    requestIDs: {
        getServerFiles: number;
    };
};

export const useFileHistoryStore = create<FileHistoryStore>()(
    immer(_set => ({
        isInitialized: false,
        hasReceivedServerFiles: false,
        hasLoadedFFmpeg: false,
        serverFiles: new Map(),
        deletingFiles: new Set(),
        uploadingFiles: new Map(),
        toUploadRegularFiles: [],
        toUploadAVFiles: [],
        toUploadFilesWaitingDeletion: new Set(),
        inFlightRegularFiles: new Set(),
        inFlightAVFiles: new Set(),
        inFlightErrorFiles: new Set(),
        requestIDs: {
            getServerFiles: 0
        }
    }))
);

export async function getServerFiles(idToken: string) {
    const id = useFileHistoryStore.getState().requestIDs.getServerFiles;

    useFileHistoryStore.setState(state => {
        state.requestIDs.getServerFiles += 1;
    });

    const { files } = await listUserFilesApi(idToken);

    if (id + 1 === useFileHistoryStore.getState().requestIDs.getServerFiles) {
        useFileHistoryStore.setState(state => {
            state.hasReceivedServerFiles = true;
            state.isInitialized = state.hasLoadedFFmpeg;

            state.serverFiles = new Map();
            for (const file of files) {
                state.serverFiles.set(file.name, file);
            }
        });
    }
}

export async function deleteServerFile(filename: string, idToken: string) {
    useFileHistoryStore.setState(state => {
        state.serverFiles.delete(filename);
        state.deletingFiles.add(filename);
    });

    try {
        await deleteUserFileApi(filename, idToken);
    } finally {
        useFileHistoryStore.setState(state => {
            state.deletingFiles.delete(filename);
            if (state.toUploadFilesWaitingDeletion.has(filename)) {
                state.toUploadFilesWaitingDeletion.delete(filename);

                if (isAVFile(filename)) {
                    state.toUploadAVFiles.push(filename);
                } else {
                    state.toUploadRegularFiles.push(filename);
                }
            }
        });

        useFileHistoryStore.setState(state => addUploaders(state, idToken));
    }
}

export async function queueFilesForUpload(files: File[], idToken: string) {
    useFileHistoryStore.setState(state => addFilesToState(state, files));
    useFileHistoryStore.setState(state => addUploaders(state, idToken));
}

function fromFileToUploadFile(file: File): UploadFile | null {
    const filename = file.name;

    const type = getType(filename);
    if (!type) {
        return null;
    }

    const size = file.size;
    if (size === 0) {
        return null;
    }

    return {
        name: filename,
        type: type,
        size: size,
        file: file,
        status: "pending"
    };
}

function addUploadFile(state: WritableDraft<FileHistoryStore>, file: UploadFile, isFileWaitingDeletion: boolean = false) {
    const filename = file.name;

    state.uploadingFiles.set(filename, file);

    if (isFileWaitingDeletion) {
        state.toUploadFilesWaitingDeletion.add(filename);
    } else if (isAVFile(filename)) {
        state.toUploadAVFiles.push(filename);
    } else {
        state.toUploadRegularFiles.push(filename);
    }
}

function addFilesToState(state: WritableDraft<FileHistoryStore>, files: File[]) {
    for (const file of files) {
        const filename = file.name;

        const uploadFile = fromFileToUploadFile(file);
        if (uploadFile) {
            if (state.serverFiles.has(filename)) {
                state.serverFiles.delete(filename);
                addUploadFile(state, uploadFile);
            } else if (state.deletingFiles.has(filename)) {
                if (!state.toUploadFilesWaitingDeletion.has(filename)) {
                    addUploadFile(state, uploadFile, true);
                }
            } else if (state.inFlightErrorFiles.has(filename)) {
                state.inFlightErrorFiles.delete(filename);
                state.uploadingFiles.delete(filename);
                addUploadFile(state, uploadFile);
            } else {
                addUploadFile(state, uploadFile);
            }
        }
    }
}

function addUploaders(state: WritableDraft<FileHistoryStore>, idToken: string) {
    const regularUploaders = CONCURRENT_REGULAR_FILE_UPLOADS - state.inFlightRegularFiles.size;
    for (let i = 0; i < regularUploaders; i++) {
        addRegularUploader(state, idToken);
    }

    const avUploaders = CONCURRENT_AV_FILE_UPLOADS - state.inFlightAVFiles.size;
    for (let i = 0; i < avUploaders; i++) {
        addAVUploader(state, idToken);
    }
}

function addRegularUploader(state: WritableDraft<FileHistoryStore>, idToken: string) {
    const filename = state.toUploadRegularFiles.shift();
    if (filename !== undefined) {
        state.inFlightRegularFiles.add(filename);
        const uploadFile = state.uploadingFiles.get(filename);
        if (uploadFile !== undefined) {
            const uploadFilename = uploadFile.name;
            uploadFile.status = "uploading";
            setTimeout(() => runRegularUploader(uploadFilename, idToken), 0);
        }
    }
}

function addAVUploader(state: WritableDraft<FileHistoryStore>, idToken: string) {
    const filename = state.toUploadAVFiles.shift();
    if (filename !== undefined) {
        state.inFlightAVFiles.add(filename);
        const uploadFile = state.uploadingFiles.get(filename);
        if (uploadFile !== undefined) {
            const uploadFilename = uploadFile.name;
            uploadFile.status = "processing";
            setTimeout(() => runAVUploader(uploadFilename, idToken), 0);
        }
    }
}

async function runRegularUploader(uploadFilename: string, idToken: string) {
    const uploadFile = useFileHistoryStore.getState().uploadingFiles.get(uploadFilename);
    if (uploadFile !== undefined) {
        const formData = new FormData();
        formData.append("file", uploadFile.file);
        try {
            await uploadUserRegularFileApi(formData, idToken);
            useFileHistoryStore.setState(state => {
                state.inFlightRegularFiles.delete(uploadFile.name);
                state.uploadingFiles.delete(uploadFile.name);
                state.serverFiles.set(uploadFile.name, { name: uploadFile.name, type: uploadFile.type, size: uploadFile.size });
            });
        } catch (__error) {
            useFileHistoryStore.setState(state => {
                state.inFlightRegularFiles.delete(uploadFile.name);
                state.inFlightErrorFiles.add(uploadFile.name);
                const uploadingFile = state.uploadingFiles.get(uploadFile.name);
                if (uploadingFile !== undefined) {
                    uploadingFile.status = "error";
                }
            });
        }

        useFileHistoryStore.setState(state => addRegularUploader(state, idToken));
    }
}

async function runAVUploader(uploadFilename: string, idToken: string) {
    const uploadFile = useFileHistoryStore.getState().uploadingFiles.get(uploadFilename);
    if (uploadFile !== undefined) {
        const convertedFile = await ffmpegTranscode(ffmpeg, uploadFile.file);
        useFileHistoryStore.setState(state => {
            const uploadingFile = state.uploadingFiles.get(uploadFile.name);
            if (uploadingFile !== undefined) {
                uploadingFile.status = "uploading";
            }
        });
        const formData = new FormData();
        formData.append("file", convertedFile);
        try {
            const { files } = await uploadUserAVFileApi(formData, idToken);
            const file = files[0];
            useFileHistoryStore.setState(state => {
                state.inFlightAVFiles.delete(uploadFile.name);
                state.uploadingFiles.delete(uploadFile.name);
                state.serverFiles.set(file.name, { name: file.name, type: file.type, size: file.size });
            });
        } catch (__error) {
            useFileHistoryStore.setState(state => {
                state.inFlightAVFiles.delete(uploadFile.name);
                state.inFlightErrorFiles.add(uploadFile.name);
                const uploadingFile = state.uploadingFiles.get(uploadFile.name);
                if (uploadingFile !== undefined) {
                    uploadingFile.status = "error";
                }
            });
        }

        useFileHistoryStore.setState(state => addAVUploader(state, idToken));
    }
}

const ffmpeg = new FFmpeg();
ffmpegLoad(ffmpeg).then(() => {
    useFileHistoryStore.setState(state => {
        state.hasLoadedFFmpeg = true;
        state.isInitialized = state.hasReceivedServerFiles;
    });
});
