import { Spinner, SpinnerSize } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";

const useClasses = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "8px",
        marginLeft: "auto",
        marginRight: "auto",
        alignItems: "center",
        alignSelf: "stretch",
        justifyContent: "center"
    },
    message: {
        color: "#242424",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "12px"
    }
});

export const Initialization = () => {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <Spinner size={SpinnerSize.large} />
            <div className={classes.message}>Initializing</div>
        </div>
    );
};
