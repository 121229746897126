import { useRef, useState, useEffect, ChangeEvent } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Slider } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import readNDJSONStream from "ndjson-readablestream";
import { appServicesToken } from "../../authConfig";

import styles from "./Chat.module.css";
import intelligenicicon from "../../Intelligenic-images/Intelligenic-icon.svg";

import {
    chatApi,
    configApi,
    getSpeechApi,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    VectorFieldOptions,
    GPT4VInput,
    fetchUserProfile,
    saveChatHistoryApi,
    getChatHistoryApi
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useLogin, getToken, isLoggedIn, requireAccessControl } from "../../authConfig";
import { VectorSettings } from "../../components/VectorSettings";
import { useMsal } from "@azure/msal-react";
import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";
import { GPT4VSettings } from "../../components/GPT4VSettings";
import { Profile } from "../../components/Profile";
import { SidePanel, SidePanelButton } from "../../components/SidePanel";
import { FileHistory } from "../../components/FileHistory";
import { getServerFiles, useFileHistoryStore } from "../../state/FileHistoryStore";
import { UserChatHistory } from "../../components/user/chat/UserChatHistory";

import { UserProfile } from "../../components/Profile/UserProfile";

const useClasses = makeStyles({
    sidePanel: {
        "@media (max-width: 640px)": {
            position: "fixed"
        }
    }
});

const Chat = () => {
    const client = useMsal().instance;
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userName = (activeAccount?.username ?? appServicesToken?.user_claims?.preferred_username) || "Guest";

    const classes = useClasses();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false); // MUST BE FALSE. Initial page render must be false.
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(0.5);
    const [minimumRerankerScore, setMinimumRerankerScore] = useState<number>(0);
    const [minimumSearchScore, setMinimumSearchScore] = useState<number>(0);
    const [retrieveCount, setRetrieveCount] = useState<number>(5);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const [vectorFieldList, setVectorFieldList] = useState<VectorFieldOptions[]>([VectorFieldOptions.Embedding]);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const [gpt4vInput, setGPT4VInput] = useState<GPT4VInput>(GPT4VInput.TextAndImages);
    const [useGPT4V, setUseGPT4V] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [speechUrls, setSpeechUrls] = useState<(string | null)[]>([]);

    const [chatHistory, setChatHistory] = useState<[user: string, response: ChatAppResponse][][]>([]);
    const [conversationId, setConverstaionId] = useState<number>(0);

    const [showGPT4VOptions, setShowGPT4VOptions] = useState<boolean>(false);
    const [showSemanticRankerOption, setShowSemanticRankerOption] = useState<boolean>(false);
    const [showVectorOption, setShowVectorOption] = useState<boolean>(false);
    const [showSpeechInput, setShowSpeechInput] = useState<boolean>(false);
    const [showSpeechOutput, setShowSpeechOutput] = useState<boolean>(false);
    const [finalEvent, setFinalEvent] = useState<any>(null);

    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);

    const closeUserProfile = () => {
        setIsUserProfileOpen(false);
    };

    const pageContainerRef = useRef<HTMLDivElement>(null);

    const getConfig = async () => {
        configApi().then(config => {
            setShowGPT4VOptions(config.showGPT4VOptions);
            setUseSemanticRanker(config.showSemanticRankerOption);
            setShowSemanticRankerOption(config.showSemanticRankerOption);
            setShowVectorOption(config.showVectorOption);
            if (!config.showVectorOption) {
                setRetrievalMode(RetrievalMode.Text);
            }
            setShowSpeechInput(config.showSpeechInput);
            setShowSpeechOutput(config.showSpeechOutput);
        });
    };

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 33);
            });
        };

        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event as ChatAppResponse;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
                setFinalEvent(event);
            }
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        return fullResponse;
    };

    // const client = useLogin ? useMsal().instance : undefined;

    useEffect(() => {
        setIsSidePanelOpen(true); // Set to true after initial page render.
    }, []);

    useEffect(() => {
        const runner = async () => {
            if (client) {
                await client.initialize();

                const token = client ? await getToken(client) : undefined;
                if (token) {
                    getServerFiles(token);
                }
            }
        };

        runner();
    }, []);

    const getBearerToken = async (): Promise<string | undefined> => {
        const token = client ? await getToken(client) : undefined;
        return token;
    };

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        const token = client ? await getToken(client) : undefined;

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                        exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                        top: retrieveCount,
                        temperature: temperature,
                        minimum_reranker_score: minimumRerankerScore,
                        minimum_search_score: minimumSearchScore,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: useSemanticRanker,
                        semantic_captions: useSemanticCaptions,
                        suggest_followup_questions: useSuggestFollowupQuestions,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        vector_fields: vectorFieldList,
                        use_gpt4v: useGPT4V,
                        gpt4v_input: gpt4vInput
                    }
                },
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };

            const response = await chatApi(request, token);
            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, response.body);
                setAnswers([...answers, [question, parsedResponse]]);
                const newChatHistory = [...chatHistory];
                if (!newChatHistory[conversationId]) {
                    newChatHistory[conversationId] = [];
                }
                newChatHistory[conversationId].push([question, parsedResponse]);
                setChatHistory(newChatHistory);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
                const newChatHistory = [...chatHistory];
                if (!newChatHistory[0]) {
                    newChatHistory[0] = [];
                }
                newChatHistory[0].push([question, parsedResponse as ChatAppResponse]);
                setChatHistory(newChatHistory);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log(chatHistory);
        if (chatHistory.length > 0) {
            saveChatHistory();
        }
    }, [chatHistory]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);
    useEffect(() => {
        getConfig();
        client?.initialize().then(() => {
            loadChatHistory();
        });
    }, []);

    useEffect(() => {
        const initializeMSAL = async () => {
            try {
                await client.initialize();
                withAuthentication((idToken: string) => {
                    fetchUserProfile(userName, idToken)
                        .then(profileData => {
                            if (profileData && Object.keys(profileData).length > 0) {
                                setIsUserProfileOpen(false);
                            } else {
                                setIsUserProfileOpen(true);
                            }
                        })
                        .catch(error => {
                            console.error("Error fetching user profile:", error);
                        });
                });
            } catch (error) {
                console.error("Error initializing MSAL:", error);
            }
        };
        initializeMSAL();
    }, []);

    useEffect(() => {
        if (answers && showSpeechOutput) {
            // For each answer that is missing a speech URL, fetch the speech URL
            for (let i = 0; i < answers.length; i++) {
                if (!speechUrls[i]) {
                    getSpeechApi(answers[i][1].choices[0].message.content).then(speechUrl => {
                        setSpeechUrls([...speechUrls.slice(0, i), speechUrl, ...speechUrls.slice(i + 1)]);
                    });
                }
            }
        }
    }, [answers]);

    const withAuthentication = (apiCall: (idToken: string) => void) => {
        try {
            if (!client) {
                throw new Error("Auth client is undefined.");
            }

            getToken(client).then(idToken => {
                if (!idToken) {
                    throw new Error("No authentication token available");
                }
                apiCall(idToken);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onTemperatureChange = (
        newValue: number,
        range?: [number, number],
        event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent
    ) => {
        setTemperature(newValue);
    };

    const onMinimumSearchScoreChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMinimumSearchScore(parseFloat(newValue || "0"));
    };

    const onMinimumRerankerScoreChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMinimumRerankerScore(parseFloat(newValue || "0"));
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const toggleSidePanel = () => {
        setIsSidePanelOpen(isSidePanelOpen => !isSidePanelOpen);
    };

    const onCloseSidePanel = () => {
        setIsSidePanelOpen(false);
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
    };

    const saveChatHistory = async () => {
        if (chatHistory.length > 0) {
            saveChatHistoryApi(chatHistory, await getBearerToken());
        }
    };

    const loadChatHistory = async () => {
        getChatHistoryApi(await getBearerToken()).then(resp => {
            if (resp.chatHistory.length > 0) {
                setChatHistory(resp.chatHistory);
            }
        });
    };

    const onStartNewChat = async () => {
        setConverstaionId(chatHistory.length);
        clearChat();
    };

    const onChatHistoryQuestionClick = async (index: number) => {
        setAnswers(chatHistory[index]);
        lastQuestionRef.current = chatHistory[index][0][0];
        document.getElementById("chatMessageStream")?.children[0].scrollIntoView({ behavior: "smooth" });
    };

    const clearChatHistory = async () => {
        setChatHistory([]);
        //saveChatHistoryApi([], await getBearerToken());
    };

    return (
        <div className={styles.chatPageContainer} ref={pageContainerRef}>
            <div className={styles.sidePanelButtonContainer}>
                <SidePanelButton onClick={toggleSidePanel} isDisabled={!isLoggedIn(client)} />
            </div>
            {/*<div className={styles.commandsContainer}>
                <div className={`${styles.commandsGroup} ${styles.commandsRight}`}>
                    <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                    <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                </div>
            </div>*/}
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <img className={styles.chatEmptyStateLogo} src={intelligenicicon} />
                            <h2 className={styles.chatEmptyStateTitle}>Welcome to Intelligenic</h2>
                            <h4 className={styles.chatEmptyStateSubtitleText}>
                                Upload SDLC files and let our platform uncover insights. You can upload video-recorded ideation/brainstorming sessions, design
                                documents, mock-ups, user stories, and other product development artifacts. After uploading your files, ask straightforward,
                                specific questions like, <strong>“What are the top trends?”</strong> or <strong>“Can you summarize key feedback?”</strong> Focus
                                on topics or timeframes for more precise answers. Upload now to begin discovering your data.
                            </h4>
                            <h3 className={styles.chatEmptyStateSubtitle}>Try one of the prompts below to see Intelligenic in action.</h3>
                            <ExampleList onExampleClicked={onExampleClicked} useGPT4V={useGPT4V} />
                        </div>
                    ) : (
                        <div id="chatMessageStream" className={styles.chatMessageStream}>
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={streamedAnswer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={true}
                                                key={index}
                                                answer={streamedAnswer[1]}
                                                isSelected={false}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                showSpeechOutput={showSpeechOutput}
                                                speechUrl={speechUrls[index]}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                showSpeechOutput={showSpeechOutput}
                                                speechUrl={speechUrls[index]}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            {!isStreaming && finalEvent?.choices[0]?.finish_reason === "length" ? (
                                <button onClick={() => makeApiRequest("Continuing the conversation")}>Continue</button>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <div className={styles.questionInputContainerWrapper}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Enter your request here "
                                disabled={isLoading}
                                onSend={question => makeApiRequest(question)}
                                showSpeechInput={showSpeechInput}
                            />
                        </div>
                        <h4 className={styles.chatEmptyStateDisclaimer}>Intelligenic can make mistakes. Please double-check responses.</h4>
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <Slider
                        className={styles.chatSettingsSeparator}
                        label="Temperature"
                        min={0}
                        max={1}
                        step={0.1}
                        defaultValue={temperature}
                        onChange={onTemperatureChange}
                        showValue
                        snapToStep
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Minimum search score"
                        min={0}
                        step={0.01}
                        defaultValue={minimumSearchScore.toString()}
                        onChange={onMinimumSearchScoreChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Minimum reranker score"
                        min={1}
                        max={4}
                        step={0.1}
                        defaultValue={minimumRerankerScore.toString()}
                        onChange={onMinimumRerankerScoreChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many search results:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />

                    {showSemanticRankerOption && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSemanticRanker}
                            label="Use semantic ranker for retrieval"
                            onChange={onUseSemanticRankerChange}
                        />
                    )}
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />

                    {showGPT4VOptions && (
                        <GPT4VSettings
                            gpt4vInputs={gpt4vInput}
                            isUseGPT4V={useGPT4V}
                            updateUseGPT4V={useGPT4V => {
                                setUseGPT4V(useGPT4V);
                            }}
                            updateGPT4VInputs={inputs => setGPT4VInput(inputs)}
                        />
                    )}

                    {showVectorOption && (
                        <VectorSettings
                            defaultRetrievalMode={retrievalMode}
                            showImageOptions={useGPT4V && showGPT4VOptions}
                            updateVectorFields={(options: VectorFieldOptions[]) => setVectorFieldList(options)}
                            updateRetrievalMode={(retrievalMode: RetrievalMode) => setRetrievalMode(retrievalMode)}
                        />
                    )}

                    {useLogin && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useOidSecurityFilter || requireAccessControl}
                            label="Use oid security filter"
                            disabled={!isLoggedIn(client) || requireAccessControl}
                            onChange={onUseOidSecurityFilterChange}
                        />
                    )}
                    {useLogin && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useGroupsSecurityFilter || requireAccessControl}
                            label="Use groups security filter"
                            disabled={!isLoggedIn(client) || requireAccessControl}
                            onChange={onUseGroupsSecurityFilterChange}
                        />
                    )}

                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label="Stream chat completion responses"
                        onChange={onShouldStreamChange}
                    />
                    {useLogin && <TokenClaimsDisplay />}
                </Panel>
            </div>

            <div className={styles.profileContainer}>
                <Profile />
            </div>

            <SidePanel className={classes.sidePanel} isOpen={isSidePanelOpen} onClose={onCloseSidePanel} mountNode={pageContainerRef.current}>
                <FileHistory />
                <div className={styles.sidePanelProfileContainer}>
                    <Profile />
                </div>
                <UserChatHistory
                    chatHistory={chatHistory}
                    onQuestionClick={onChatHistoryQuestionClick}
                    onStartNewChat={onStartNewChat}
                    onClose={onCloseSidePanel}
                />
            </SidePanel>
            {isUserProfileOpen && (
                <UserProfile
                    isOpen={isUserProfileOpen}
                    onClose={closeUserProfile}
                    userName={userName}
                    isProfileUpdate={false} // Fallback to "Guest" if username is null
                />
            )}
        </div>
    );
};

export default Chat;
