import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

export async function load(ffmpeg: FFmpeg) {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm";

    await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm")
    });
}

export async function transcode(ffmpeg: FFmpeg, file: File) {
    await ffmpeg.writeFile(file.name, await fetchFile(file));

    await ffmpeg.exec(["-i", file.name, "-b:a", "8K", "-vn", file.name + ".mp3"]);

    const data = await ffmpeg.readFile(file.name + ".mp3");

    await ffmpeg.deleteFile(file.name);
    await ffmpeg.deleteFile(file.name + ".mp3");

    return new File([data], file.name + ".mp3");
}
