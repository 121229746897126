import { useState } from "react";

import { makeStyles, mergeClasses, Button } from "@fluentui/react-components";
import { IconFile } from "@tabler/icons-react";

import { UserFile } from "../../../api";
import { FileItem } from "./FileItem";
import { useFileHistoryStore, UploadFile } from "../../../state/FileHistoryStore";
import { HiddenUploadFileInput } from "./HiddenUploadFileInput";
import { FileDropzone } from "./FileDropzone";

const NUMBER_OF_DISPLAYED_FILEITEMS = import.meta.env.VITE_NUMBER_OF_DISPLAYED_FILEITEMS || 9;

const useClasses = makeStyles({
    container: {
        position: "relative",
        display: "flex",
        alignSelf: "stretch",
        alignItems: "center",
        flexDirection: "column"
    },
    dropzone: {
        opacity: "0.33",
        position: "absolute",
        width: "100%",
        height: "100%",

        display: "none",
        justifyContent: "center",
        alignItems: "center",

        borderRadius: "4px",
        border: "1px dashed #103699",
        background: "#ecf1ff",
        color: "#103699"
    },
    dropzoneOver: {
        display: "flex"
    },
    content: {
        display: "flex",
        pointerEvents: "none"
    }
});

export const WithFileItems = () => {
    const classes = useClasses();

    const [isOver, setIsOver] = useState(false);

    const serverFiles = useFileHistoryStore(state => state.serverFiles);
    const uploadingFiles = useFileHistoryStore(state => state.uploadingFiles);

    const dropzoneClassName = isOver ? mergeClasses(classes.dropzone, classes.dropzoneOver) : classes.dropzone;

    let count = 0;

    const uploadingFilenames = Array.from(uploadingFiles.keys());
    const renderUploadFiles: UploadFile[] = [];
    for (const filename of uploadingFilenames) {
        if (count < NUMBER_OF_DISPLAYED_FILEITEMS) {
            const file = uploadingFiles.get(filename);
            if (file) {
                count += 1;
                renderUploadFiles.push(file);
            }
        } else {
            break;
        }
    }

    const serverFilenames = Array.from(serverFiles.keys()).sort();
    const renderServerFiles: UserFile[] = [];
    for (const filename of serverFilenames) {
        if (count < NUMBER_OF_DISPLAYED_FILEITEMS) {
            const file = serverFiles.get(filename);
            if (file) {
                count += 1;
                renderServerFiles.push(file);
            }
        } else {
            break;
        }
    }

    const onDragEnter = () => setIsOver(true);

    const onDragLeave = () => setIsOver(false);

    return (
        <div className={classes.container} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            {renderUploadFiles.map(file => (
                <FileItem key={"uploading" + file.name} kind="uploading" file={file} />
            ))}
            {renderServerFiles.map(file => (
                <FileItem key={"server" + file.name} kind="server" file={file} />
            ))}
            <UploadButton />
            <FileDropzone classContainer={dropzoneClassName} classContainerOver={classes.dropzoneOver}>
                <div className={classes.content}>
                    <IconFile size={36} stroke={1} />
                </div>
            </FileDropzone>
        </div>
    );
};

const useButtonClasses = makeStyles({
    container: {
        display: "flex",
        alignSelf: "stretch",
        padding: "12px 8px"
    },
    button: {
        width: "100%",
        padding: "8px 16px",
        borderRadius: "4px",
        backgroundColor: "#103699",

        ":hover": {
            backgroundColor: "#2156e1"
        },
        ":hover:active": {
            backgroundColor: "#0b256a"
        }
    },
    text: {
        color: "#ffffff",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px",
        padding: "0",
        margin: "1px 0px"
    }
});

const UploadButton = () => {
    const classes = useButtonClasses();

    return (
        <HiddenUploadFileInput className={classes.container}>
            <Button className={classes.button} aria-label="Upload button">
                <div className={classes.text}>Upload</div>
            </Button>
        </HiddenUploadFileInput>
    );
};
