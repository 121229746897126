import { Button, makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
    profileButton: {
        ...shorthands.borderRadius("4px"),
        ...shorthands.border("0"),
        ...shorthands.padding("2px"),
        width: "244px",
        justifyContent: "start",
        backgroundColor: "#ffffff",
        boxShadow: ["0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)"],
        ":hover": {
            backgroundColor: "#ecf1ff"
        },
        ":hover:active": {
            backgroundColor: "#ecf1ff"
        }
    },
    profileText: {
        color: "#242424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        ...shorthands.padding("6px", "0px", "6px", "6px")
    }
});

export const UserProfileButton = ({ onProfileButtonClick }: { onProfileButtonClick: () => void }) => {
    const classes = useStyles();

    return (
        <Button className={classes.profileButton} onClick={onProfileButtonClick}>
            <span className={classes.profileText}>Update Profile</span>
        </Button>
    );
};
