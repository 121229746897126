import { ReactNode } from "react";

import { makeStyles, mergeClasses, Button, OverlayDrawer, OverlayDrawerProps } from "@fluentui/react-components";

import { IconX } from "@tabler/icons-react";

import intelligeniclogo from "../../Intelligenic-images/Intelligenic_icon-16x16.svg";

const useClasses = makeStyles({
    overlay: {
        display: "flex",
        width: "208px",
        padding: "6px 0px",
        alignItems: "flex-start",
        overflow: "visible",
        maxHeight: "none"
    },
    container: {
        display: "flex",
        padding: "6px",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 0",
        alignSelf: "stretch",
        borderRadius: "0px 6px 6px 0px",
        background: "#ffffff",
        overflow: "visible",
        boxShadow: ["0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)"]
    },
    header: {
        display: "flex",
        padding: "4px 6px 8px 10px",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "4px 4px 4px 4px",
        justifyContent: "space-between"
    },
    closeButton: {
        minWidth: "16px"
    }
});

export type SidePanelProps = {
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    mountNode?: OverlayDrawerProps["mountNode"];
    children: ReactNode;
};

export const SidePanel = (props: SidePanelProps) => {
    const classes = useClasses();

    return (
        <OverlayDrawer className={mergeClasses(props.className, classes.overlay)} modalType="non-modal" open={props.isOpen} mountNode={props.mountNode}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <img src={intelligeniclogo} alt="Intelligenic Logo" />
                    <Button className={classes.closeButton} aria-label="Close side panel" onClick={props.onClose}>
                        <IconX color="#797979" size={16} />
                    </Button>
                </div>
                {props.children}
            </div>
        </OverlayDrawer>
    );
};
