const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, SimpleAPIResponse, UserFilesResponse, ChatHistory, UserProfiles } from "./models";
import { useLogin, appServicesToken } from "../authConfig";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            return { Authorization: `Bearer ${idToken}` };
        }
    }

    return {};
}

export async function configApi(): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET"
    });

    return (await response.json()) as Config;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export async function chatShadowApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat-shadow`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export async function getChatHistoryApi(idToken: string | undefined): Promise<ChatHistory> {
    const response = await fetch(`${BACKEND_URI}/chat/history`, {
        method: "GET",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" }
    });

    if (!response.ok) {
        throw new Error(`Getting chat history failed: ${response.statusText}`);
    }

    const chatHistory: ChatHistory = await response.json();

    return chatHistory as ChatHistory;
}

export async function saveChatHistoryApi(history: [user: string, response: ChatAppResponse][][], idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat/history`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify({ chatHistory: history })
    });
}

export async function getSpeechApi(text: string): Promise<string | null> {
    return await fetch("/speech", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            text: text
        })
    })
        .then(response => {
            if (response.status == 200) {
                return response.blob();
            } else if (response.status == 400) {
                console.log("Speech synthesis is not enabled.");
                return null;
            } else {
                console.error("Unable to get speech synthesis.");
                return null;
            }
        })
        .then(blob => (blob ? URL.createObjectURL(blob) : null));
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function uploadUserRegularFileApi(request: FormData, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/api/user/files/upload", {
        method: "POST",
        headers: getHeaders(idToken),
        body: request
    });

    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function uploadUserAVFileApi(request: FormData, idToken: string): Promise<UserFilesResponse> {
    const response = await fetch("/api/user/files/av_upload", {
        method: "POST",
        headers: getHeaders(idToken),
        body: request
    });

    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    return await response.json();
}

export async function deleteUserFileApi(filename: string, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/api/user/files/delete", {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify({ filename })
    });

    if (!response.ok) {
        throw new Error(`Deleting file failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function listUserFilesApi(idToken: string): Promise<UserFilesResponse> {
    const response = await fetch(`/api/user/files/list`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Listing files failed: ${response.statusText}`);
    }

    return await response.json();
}

export async function updateUserProfileApi(request: Record<string, any>, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/api/user/profile/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(request)
    });

    if (!response.ok) {
        throw new Error(`Creating profile failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function fetchUserProfile(userName: string, idToken: string): Promise<UserProfiles> {
    const apiUrl = `/api/user/profile/${userName}`;

    const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
            ...getHeaders(idToken)
        }
    });

    if (!response.ok) {
        throw new Error(`Fetching profile failed: ${response.statusText}`);
    }

    const profileData: UserProfiles = await response.json();
    return profileData;
}
