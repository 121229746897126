import { useEffect, useState } from "react";
import { getToken } from "../../../authConfig";
import { UserProfilePanel } from "../UserProfilePanel";
import { updateUserProfileApi, UserProfiles } from "../../../api";
import { useMsal } from "@azure/msal-react";
import { useUser } from "../../../context/userContext";

export interface UserProfileProps {
    isOpen: boolean;
    onClose: () => void;
    userName: string;
    isProfileUpdate: boolean;
}

export const UserProfile = ({ isOpen, onClose, userName, isProfileUpdate }: UserProfileProps) => {
    const client = useMsal().instance;
    const { updateUserProfile } = useUser();

    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (isOpen) {
            setIsPanelOpen(true);
        } else {
            setIsPanelOpen(false);
            onCloseProfilePanel();
        }
    }, [isOpen, userName]);

    const withAuthentication = async (apiCall: (idToken: string) => void) => {
        try {
            const idToken = await getToken(client);
            if (!idToken) throw new Error("No authentication token available");

            apiCall(idToken);
        } catch (error) {
            setErrorMessage("Authentication error. Please try again.");
        }
    };

    const onCloseProfilePanel = () => {
        onClose();
        setIsPanelOpen(false);
        setErrorMessage(null);
    };

    const submitUserProfile = (profileData: Record<string, any>, onSuccess: () => void) => {
        setIsSaving(true);
        setErrorMessage(null);
        withAuthentication((idToken: string) => {
            updateUserProfileApi(profileData, idToken)
                .then(resp => {
                    updateUserProfile(profileData as UserProfiles);
                    onSuccess();
                })
                .catch(error => {
                    setErrorMessage("Error encountered. Please try again.");
                })
                .finally(() => {
                    setIsSaving(false);
                });
        });
    };

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const profileData = {
            firstName: (e.currentTarget.elements.namedItem("firstName") as HTMLInputElement)?.value || "",
            lastName: (e.currentTarget.elements.namedItem("lastName") as HTMLInputElement)?.value || "",
            companyName: (e.currentTarget.elements.namedItem("companyName") as HTMLInputElement)?.value || "",
            companyDesc: (e.currentTarget.elements.namedItem("companyDesc") as HTMLInputElement)?.value || "",
            industry: (e.currentTarget.elements.namedItem("industry") as HTMLInputElement)?.value || "",
            department: (e.currentTarget.elements.namedItem("department") as HTMLInputElement)?.value || "",
            role: (e.currentTarget.elements.namedItem("role") as HTMLInputElement)?.value || "",
            userName: userName
        };

        submitUserProfile(profileData, onCloseProfilePanel);
    };

    return isOpen ? (
        <div>
            <UserProfilePanel
                isSaving={isSaving}
                isOpen={isPanelOpen}
                onClose={onCloseProfilePanel}
                onFormSubmit={onFormSubmit}
                isProfileUpdate={isProfileUpdate}
                errorMessage={errorMessage || ""}
            />
        </div>
    ) : null;
};
