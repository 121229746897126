import { makeStyles } from "@fluentui/react-components";
import { IconFile } from "@tabler/icons-react";

import { HiddenUploadFileInput } from "./HiddenUploadFileInput";
import { FileDropzone } from "./FileDropzone";

const useClasses = makeStyles({
    spacer: {
        display: "flex",
        width: "190px",
        maxHeight: "360px",
        padding: "0px 2px 0px 8px",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 0 0",
        borderRadius: "4px"
    },
    container: {
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch"
    },
    dropzone: {
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 0 0",
        alignSelf: "stretch",
        borderRadius: "4px",
        border: "1px dashed #797979",
        background: "#fafafa",
        color: "#797979",

        ":hover": {
            border: "1px dashed #103699",
            background: "#ecf1ff",
            color: "#103699",
            cursor: "pointer"
        }
    },
    dropzoneOver: {
        border: "1px dashed #103699",
        background: "#ecf1ff",
        color: "#103699"
    },
    content: {
        display: "flex",
        height: "83px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        alignSelf: "stretch",
        pointerEvents: "none"
    },
    message: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: "1 0 0",
        alignSelf: "stretch",

        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal"
    }
});

export const NoFileItems = () => {
    const classes = useClasses();
    return (
        <div className={classes.spacer}>
            <HiddenUploadFileInput className={classes.container}>
                <FileDropzone classContainer={classes.dropzone} classContainerOver={classes.dropzoneOver}>
                    <div className={classes.content}>
                        <IconFile size={36} stroke={1} />
                        <div className={classes.message}>Click or Drag & Drop files here to add to chat</div>
                    </div>
                </FileDropzone>
            </HiddenUploadFileInput>
        </div>
    );
};
