import { makeStyles } from "@fluentui/react-components";

import { Initialization } from "./components/Initialization";
import { NoFileItems } from "./components/NoFileItems";
import { useFileHistoryStore } from "../../state/FileHistoryStore";
import { WithFileItems } from "./components/WithFileItems";

const useClasses = makeStyles({
    header: {
        display: "flex",
        padding: "8px 6px 8px 10px",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "4px",

        color: "#242424",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal"
    }
});

export const FileHistory = () => {
    const classes = useClasses();

    const isInitialized = useFileHistoryStore(state => state.isInitialized);
    const serverFiles = useFileHistoryStore(state => state.serverFiles);
    const uploadingFiles = useFileHistoryStore(state => state.uploadingFiles);

    const hasNoFiles = serverFiles.size + uploadingFiles.size === 0;

    return (
        <>
            <header className={classes.header}>File History</header>
            {!isInitialized && <Initialization />}
            {isInitialized && hasNoFiles && <NoFileItems />}
            {isInitialized && !hasNoFiles && <WithFileItems />}
        </>
    );
};
