import { Button, makeStyles, shorthands } from "@fluentui/react-components";

import { useMsal } from "@azure/msal-react";

import styles from "./LoginButton.module.css";
import { getRedirectUri, loginRequest } from "../../authConfig";
import { appServicesToken, appServicesLogout } from "../../authConfig";

const useStyles = makeStyles({
    loginButton: {
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "#5d5d5d"),
        ...shorthands.padding("10px", "18px", "10px", "18px"),
        minWidth: "79px",

        backgroundColor: "#5d5d5d",
        boxShadow: ["0px 1px 2px 0px rgba(16, 24, 40, 0.05)"],

        ":hover": {
            backgroundColor: "#5d5d5d"
        },
        ":hover:active": {
            backgroundColor: "#5d5d5d"
        },

        "@media (max-width: 640px)": {
            ...shorthands.padding("16px", "28px", "16px", "28px")
        }
    },
    loginText: {
        color: "#fff",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",

        "@media (max-width: 640px)": {
            fontSize: "18px",
            lineHeight: "28px"
        }
    },
    logoutButton: {
        ...shorthands.borderRadius("4px"),
        ...shorthands.border("0"),

        ...shorthands.padding("2px"),

        width: "244px",
        justifyContent: "start",

        backgroundColor: "#ffffff",
        boxShadow: ["0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)"],

        ":hover": {
            backgroundColor: "#ecf1ff"
        },
        ":hover:active": {
            backgroundColor: "#ecf1ff"
        }
    },
    logoutText: {
        color: "#242424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",

        ...shorthands.padding("6px", "0px", "6px", "6px")
    }
});

export const LoginButton = () => {
    const classes = useStyles();

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const isLoggedIn = (activeAccount || appServicesToken) != null;

    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri()
            })
            .catch(error => console.log(error));
    };
    const handleLogoutPopup = () => {
        if (activeAccount) {
            instance
                .logoutPopup({
                    mainWindowRedirectUri: "/", // redirects the top level app after logout
                    account: instance.getActiveAccount()
                })
                .catch(error => console.log(error));
        } else {
            appServicesLogout();
        }
    };

    if (isLoggedIn) {
        return (
            <Button className={classes.logoutButton} onClick={handleLogoutPopup}>
                <span className={classes.logoutText}>Log Out</span>
            </Button>
        );
    } else {
        return (
            <Button className={classes.loginButton} onClick={handleLoginPopup}>
                <span className={classes.loginText}>Login</span>
            </Button>
        );
    }
};
