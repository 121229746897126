import { useId, ChangeEventHandler, ReactNode } from "react";
import { makeStyles } from "@fluentui/react-components";

const useClasses = makeStyles({
    hidden: {
        display: "none"
    }
});

export type HiddenFileInputProps = {
    className?: string;
    accept?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    multiple?: boolean;
    children: ReactNode;
};

export const HiddenFileInput = (props: HiddenFileInputProps) => {
    const classes = useClasses();
    const id = useId();

    const onClick = () => document.getElementById(id)?.click();

    return (
        <div className={props.className} onClick={onClick}>
            {props.children}
            <input id={id} className={classes.hidden} accept={props.accept} type="file" onChange={props.onChange} multiple={props.multiple} />
        </div>
    );
};
