import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { SpeechOutput } from "./SpeechOutput";
import { marked } from "marked";
import { Button } from "@fluentui/react-components";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onAnswerDrawerClicked?: () => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    showSpeechOutput?: boolean;
    speechUrl: string | null;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onAnswerDrawerClicked,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    showSpeechOutput,
    speechUrl
}: Props) => {
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);

    //Adding boolean variables for Showing Thought process and Citation Icons on Answer/response page. In future, get this value from either User Settings or Env Variables)
    const SHOW_THOUGHT_PROCESS_SUPPORTING_CONTENT = Boolean(false);
    const SHOW_CITATION_HYPER_LINK = Boolean(false);

    const sanitizedAnswerHtml = marked.parse(DOMPurify.sanitize(parsedAnswer.answerHtml));

    const handlePRDClick = () => {
        onAnswerDrawerClicked && onAnswerDrawerClicked();
    };

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        {SHOW_THOUGHT_PROCESS_SUPPORTING_CONTENT ? (
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "Lightbulb" }}
                                title="Show thought process"
                                ariaLabel="Show thought process"
                                onClick={() => onThoughtProcessClicked()}
                                disabled={!answer.choices[0].context.thoughts?.length}
                            />
                        ) : null}
                        {SHOW_THOUGHT_PROCESS_SUPPORTING_CONTENT ? (
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "ClipboardList" }}
                                title="Show supporting content"
                                ariaLabel="Show supporting content"
                                onClick={() => onSupportingContentClicked()}
                                disabled={!answer.choices[0].context.data_points}
                            />
                        ) : null}
                        {showSpeechOutput && <SpeechOutput url={speechUrl} />}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                {sanitizedAnswerHtml.trim() === "<p>IS_PRD</p>" ? (
                    <div className={styles.answerText}>
                        <Button onClick={() => handlePRDClick()} style={{ border: "solid 1px gray", padding: "3px" }}>
                            Synthetic PRD - version 1.0
                        </Button>
                    </div>
                ) : (
                    <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                )}
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            if (SHOW_CITATION_HYPER_LINK == true) {
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            } else {
                                return (
                                    <span key={i} title={x}>
                                        {`${++i}. ${x}`}
                                    </span>
                                );
                            }
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
