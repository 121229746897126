import { Spinner, SpinnerSize } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";

import { useMsal } from "@azure/msal-react";

import { IconFile, IconTrash } from "@tabler/icons-react";

import { getToken } from "../../../authConfig";
import { UserFile } from "../../../api";
import { deleteServerFile, UploadFile } from "../../../state/FileHistoryStore";

const useClasses = makeStyles({
    container: {
        display: "flex",
        padding: "4px 6px",
        alignItems: "center",
        gap: "2px",
        alignSelf: "stretch",
        borderRadius: "4px",

        ":hover": {
            background: "#ebf3fc"
        }
    },
    type: {
        display: "flex"
    },
    info: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "3px",
        flex: "1 0 0",
        alignSelf: "stretch"
    },
    name: {
        alignSelf: "stretch",
        overflow: "hidden",
        color: "#242424",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Currently hardcoded
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "12px"
    },
    metadata: {
        color: "#b4b4b4",
        fontFamily: "Poppins",
        fontSize: "7px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "8px",
        textTransform: "uppercase"
    },
    action: {
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch"
    },
    trashContainer: {
        color: "#797979",
        cursor: "pointer",

        ":hover": {
            color: "#103699"
        }
    }
});

export type FileItemProps = { kind: "server"; file: UserFile } | { kind: "uploading"; file: UploadFile };

export const FileItem = (props: FileItemProps) => {
    const classes = useClasses();

    const client = useMsal().instance;

    const onClickTrash = async () => {
        const idToken = await getToken(client);
        if (idToken) {
            await deleteServerFile(props.file.name, idToken);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.type}>
                <IconFile color="#797979" size={24} />
            </div>
            <div className={classes.info}>
                <div className={classes.name}>{props.file.name}</div>
                <div className={classes.metadata}>
                    {props.kind === "server" && `${props.file.type} - ${formatBytes(props.file.size)}`}
                    {props.kind === "uploading" && props.file.status}
                </div>
            </div>
            <div className={classes.action}>
                {props.kind === "server" && (
                    <div className={classes.trashContainer} onClick={onClickTrash}>
                        <IconTrash size={16} />
                    </div>
                )}
                {props.kind === "uploading" && <Spinner size={SpinnerSize.small} />}
            </div>
        </div>
    );
};

const formatBytes = (bytes: number, decimals = 0) => {
    if (!+bytes) return "0 B";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
