import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/github.svg";

import intelligeniclogo from "../../Intelligenic-images/Intelligenic-logo.svg";
import splashIntelligenicLogo from "../../Intelligenic-images/Intelligenic-logo-splash.svg";

import styles from "./Layout.module.css";

import { appServicesToken, useLogin } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { LoginButton } from "../../components/LoginButton";

import Splash from "../splash/Splash";

const Layout = () => {
    const { instance } = useMsal();

    if (useLogin) {
        const activeAccount = instance.getActiveAccount();
        const isLoggedIn = (activeAccount || appServicesToken) != null;

        if (!isLoggedIn) {
            return (
                <div className={styles.splashLayout}>
                    <header className={styles.splashNavContainer}>
                        <Link to="#" className={styles.splashNavLogoContainer}>
                            <img
                                srcSet={`${intelligeniclogo} 137w, ${splashIntelligenicLogo} 200w`}
                                sizes="(max-width: 640px) 137px, 200px"
                                src={splashIntelligenicLogo}
                                alt="Intelligenic Logo"
                            />
                        </Link>
                        <div className={styles.splashNavSignInContainer}>{useLogin && <LoginButton />}</div>
                    </header>
                    <Splash></Splash>
                </div>
            );
        }
    }

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>
                            <a href="https://www.intelligenic.ai/" target={"_blank"}>
                                <img src={intelligeniclogo} />
                            </a>
                        </h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}></NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink
                                    to="/qa"
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                ></NavLink>
                            </li>
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}></NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink
                                    to="/qa"
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                ></NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a href="" target={"_blank"} title=""></a>
                            </li>
                        </ul>
                    </nav>
                    <h4 className={styles.headerRightText}>SDLC Co-Pilot</h4>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
