import { makeStyles, Table, TableBody, TableRow, TableCell, Spinner, Label, Button } from "@fluentui/react-components";
import { ChatBubblesQuestion24Regular } from "@fluentui/react-icons";
import { ChatAppResponse, UserFile } from "../../../../api";

import styles from "./UserChatHistoryTable.module.css";

const useClasses = makeStyles({
    iconCell: {
        border: "0",
        width: "20%",
        padding: "5px !important",
        textAlign: "left",
        verticalAlign: "center"
    },

    questionCell: {
        border: "0",
        fontWeight: "bold",
        padding: "10px !important",
        ">button": {
            textAlign: "left",
            backgroundColor: "#fff",
            fontSize: "12px"
        }
    },

    table: {
        border: "0",
        margin: "0"
    },

    row: {
        borderBottom: "0",
        backgroundColor: "#fff !important"
    },

    upload: {
        marginTop: "20px",
        fontSize: "14px"
    },

    profileContainer: {
        position: "fixed",
        left: "16px",
        bottom: "7px"
    }
});

export interface UserChatHistoryTableProps {
    chatHistory: [user: string, response: ChatAppResponse][][];
    onQuestionClick: (index: number) => void;
    onClose: () => void;
}

interface ChatHistoryCellProps {
    onClick: (index: number) => void;
    onClose: () => void;
    question: string;
    index: number;
}

export const UserChatHistoryTable = ({ chatHistory, onQuestionClick, onClose }: UserChatHistoryTableProps) => {
    const classes = useClasses();
    return (
        <div>
            <Table arial-label="Chat Questions" className={classes.table}>
                <TableBody>
                    {chatHistory.map((response, i) => (
                        <TableRow key={i} className={classes.row}>
                            <IconCell />
                            <ChatHistoryCell onClick={onQuestionClick} onClose={onClose} question={response[0][0]} index={i} />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

const NoChatHistoryMessage = () => {
    return (
        <div style={{ paddingTop: "25px", textAlign: "center" }}>
            <Label>No chat history found.</Label>
        </div>
    );
};

const IconCell = () => {
    const classes = useClasses();
    return (
        <TableCell className={classes.iconCell}>
            <ChatBubblesQuestion24Regular />
        </TableCell>
    );
};

const ChatHistoryCell = ({ onClick, onClose, question, index }: ChatHistoryCellProps) => {
    const classes = useClasses();
    const onQuestionClick = () => {
        onClick(index);
    };

    return (
        <TableCell className={classes.questionCell}>
            <Button onClick={onQuestionClick}>{question}</Button>
        </TableCell>
    );
};
