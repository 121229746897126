import { makeStyles, Button } from "@fluentui/react-components";

import { IconLayoutSidebar } from "@tabler/icons-react";

const useClasses = makeStyles({
    button: {
        minWidth: "24px"
    }
});

export interface SidePanelButtonProps {
    onClick: () => void;
    isDisabled: boolean;
}

export const SidePanelButton = (props: SidePanelButtonProps) => {
    const classes = useClasses();

    return (
        <Button className={classes.button} aria-label="Side panel button" onClick={props.onClick}>
            <IconLayoutSidebar color="#797979" size={24} />
        </Button>
    );
};
