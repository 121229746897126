import { getExtension, getUploadableExtensions } from "../FileExtensions";

export function appendFileSystemEntryToFileArray(entry: FileSystemEntry, files: File[]) {
    return new Promise<null>(resolve => {
        if (entry.isFile) {
            const fileEntry = entry as FileSystemFileEntry;
            const extension = getExtension(fileEntry.name);
            if (getUploadableExtensions().has(extension)) {
                fileEntry.file(
                    file => {
                        files.push(file);
                        resolve(null);
                    },
                    () => {
                        resolve(null);
                    }
                );
            } else {
                resolve(null);
            }
        } else if (entry.isDirectory) {
            const directoryEntry = entry as FileSystemDirectoryEntry;
            const reader = directoryEntry.createReader();
            reader.readEntries(entries => {
                const promises: Promise<null>[] = [];
                for (const childEntry of entries) {
                    promises.push(appendFileSystemEntryToFileArray(childEntry, files));
                }
                Promise.allSettled(promises).finally(() => resolve(null));
            });
        } else {
            resolve(null);
        }
    });
}

export function filterUniqueFiles(files: File[]) {
    const store = new Map<string, File>();
    const duplicates = new Set<string>();

    for (const file of files) {
        const filename = file.name;
        if (store.has(filename)) {
            duplicates.add(filename);
        } else {
            store.set(filename, file);
        }
    }

    for (const filename of duplicates) {
        store.delete(filename);
    }

    return Array.from(store.values());
}
