import React, { useState, ChangeEvent, FocusEvent, FormEvent, useEffect } from "react";
import { makeStyles } from "@fluentui/react-components";
import { Spinner } from "@fluentui/react";
import { useUser } from "../../../context/userContext";

const useClasses = makeStyles({
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px",
        backgroundColor: "#fff",
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        fontFamily: "SogSegoe UI"
    },
    profileForm: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2px"
    },
    flexRow: {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px"
    },
    formField: {
        display: "flex",
        flexDirection: "column",
        gap: "3px",
        "& input, & textarea": {
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            fontSize: "12px",
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: "#f7f9fc"
        }
    },
    label: {
        paddingTop: "5px",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "14px",
        fontWeight: "500",
        color: "#333",
        position: "relative",
        "&::after": {
            content: '" *"',
            color: "#B10E1C",
            fontWeight: "bold",
            marginLeft: "2px"
        }
    },
    noAsterisk: {
        "&::after": {
            content: '""'
        }
    },
    textarea: {
        resize: "none",
        height: "80px"
    },
    submitButton: {
        backgroundColor: "#1a73e8",
        color: "#fff",
        padding: "12px",
        border: "none",
        borderRadius: "8px",
        fontSize: "14px",
        cursor: "pointer",
        width: "100%",
        textAlign: "center",
        marginTop: "15px",
        "&:hover": {
            backgroundColor: "#1666cc"
        }
    },
    subTitle: {
        textAlign: "center",
        fontFamily: "Poppins"
    },

    spinner: {
        paddingTop: "10px"
    },
    submitButtonEnabled: {
        backgroundColor: "#103699",
        color: "#fff",
        padding: "12px",
        border: "none",
        borderRadius: "4px",
        fontSize: "14px",
        fontFamily: "Poppins",
        cursor: "pointer",
        width: "100%",
        textAlign: "center",
        marginTop: "15px",
        "&:hover": {
            backgroundColor: "#2156e1"
        }
    },
    text: {
        color: "#ffffff",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px",
        padding: "0",
        margin: "1px 0px"
    },
    submitButtonDisabled: {
        backgroundColor: "#ccc",
        color: "#666",
        padding: "12px",
        border: "none",
        borderRadius: "8px",
        fontSize: "14px",
        cursor: "not-allowed",
        width: "100%",
        textAlign: "center",
        marginTop: "15px"
    },
    submitButtonWrapper: {
        position: "relative",
        marginTop: "10px",
        marginBottom: "10px"
    },
    error: {
        color: "red",
        textAlign: "center",
        paddingTop: "15px"
    }
});

export interface UserProfileFormProps {
    onFormSubmit: (e: FormEvent<HTMLFormElement>) => void;
    isProfileUpdate: boolean;
    isSaving: boolean;
    errorMessage: string | null;
}

const UserProfileForm: React.FC<UserProfileFormProps> = ({ onFormSubmit, isProfileUpdate, isSaving, errorMessage }) => {
    const classes = useClasses();
    const { userProfile } = useUser();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        companyDesc: "",
        industry: "",
        department: "",
        role: ""
    });

    // Update formData when userProfile changes
    useEffect(() => {
        if (userProfile) {
            setFormData({
                firstName: userProfile.firstName || "",
                lastName: userProfile.lastName || "",
                companyName: userProfile.companyName || "",
                companyDesc: userProfile.companyDesc || "",
                industry: userProfile.industry || "",
                department: userProfile.department || "",
                role: userProfile.role || ""
            });
        }
    }, [userProfile]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleInputBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['";]/g, "");

        if (value.length === 0) {
            e.target.setCustomValidity("");
        } else if (/^[-\s]+$/.test(value)) {
            e.target.setCustomValidity("Invalid input.");
        } else if (!/[a-zA-Z]/.test(sanitizedValue)) {
            e.target.setCustomValidity("Invalid input.");
        } else if (name === "companyDesc") {
            if (sanitizedValue.length < 10) {
                e.target.setCustomValidity("Must be at least 10 characters.");
            } else if (sanitizedValue.length > 450) {
                e.target.setCustomValidity("Cannot exceed 450 characters.");
            } else {
                e.target.setCustomValidity("");
            }
        } else {
            if (sanitizedValue.length > 50) {
                e.target.setCustomValidity("Cannot exceed 50 characters.");
            } else {
                e.target.setCustomValidity("");
            }
        }

        e.target.reportValidity();
    };

    const isFormValid = () => {
        const hasAlphabetsRegex = /[a-zA-Z]/;
        const onlyNumbersAndSpecialCharsRegex = /^[^a-zA-Z]*$/;

        return Object.values(formData).every(value => {
            const trimmedValue = value.trim();
            return trimmedValue !== "" && (hasAlphabetsRegex.test(trimmedValue) || !onlyNumbersAndSpecialCharsRegex.test(trimmedValue));
        });
    };

    return (
        <div className={classes.formContainer}>
            <p className={classes.subTitle}>
                {isProfileUpdate
                    ? "Keep your profile up-to-date to help our AI provide tailored results."
                    : "In order to get started, we need a little information about you."}
            </p>
            <form className={classes.profileForm} onSubmit={onFormSubmit}>
                <div className={classes.flexRow}>
                    <div className={classes.formField}>
                        <label htmlFor="firstName" className={classes.label}>
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </div>
                    <div className={classes.formField}>
                        <label htmlFor="lastName" className={classes.label}>
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </div>
                </div>
                <div className={classes.formField}>
                    <label htmlFor="companyName" className={classes.label}>
                        Company
                    </label>
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        placeholder="Company"
                        value={formData.companyName}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </div>
                <div className={classes.formField}>
                    <label htmlFor="companyDesc" className={classes.label}>
                        Company Description
                    </label>
                    <textarea
                        id="companyDesc"
                        name="companyDesc"
                        placeholder="Company Description"
                        value={formData.companyDesc}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </div>
                <div className={classes.formField}>
                    <label htmlFor="industry" className={classes.label}>
                        Industry
                    </label>
                    <input
                        type="text"
                        id="industry"
                        name="industry"
                        placeholder="Industry"
                        value={formData.industry}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </div>
                <div className={classes.formField}>
                    <label htmlFor="department" className={classes.label}>
                        Department
                    </label>
                    <input
                        type="text"
                        id="department"
                        name="department"
                        placeholder="Department"
                        value={formData.department}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </div>
                <div className={classes.formField}>
                    <label htmlFor="role" className={classes.label}>
                        Role
                    </label>
                    <input type="text" id="role" name="role" placeholder="Role" value={formData.role} onChange={handleInputChange} onBlur={handleInputBlur} />
                </div>
                {errorMessage ? (
                    <div className={classes.error}>An error occurred. Please try again.</div>
                ) : isSaving ? (
                    <div style={{ padding: "5px" }}>
                        <Spinner label="Saving..." />
                    </div>
                ) : (
                    <button type="submit" className={isFormValid() ? classes.submitButtonEnabled : classes.submitButtonDisabled} disabled={!isFormValid()}>
                        {isProfileUpdate ? <div className={classes.text}>Update Profile</div> : <div className={classes.text}>Get Started</div>}
                    </button>
                )}
            </form>
        </div>
    );
};

export default UserProfileForm;
